import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { NavLink } from '../general/util/Links'
import { connect } from 'kea'
import auth from '../../kea/auth'
import some from 'lodash/some'
import HTML from '../general/util/HTML'

import './OrderCTA.scss'

@connect({
  props: [
    auth, [
      'premiumUser',
    ],
  ],
})
class OrderCTA extends Component {
  static propTypes = {
    categories: PropTypes.array,
    tags: PropTypes.array,
    premiumUser: PropTypes.bool,
  }

  constructor (props) {
    super(props)
    window.dataLayer && window.dataLayer.push({ event: 'OrderCTA.view' })
  }

  render () {
    const { premiumUser, tags } = this.props
    if (premiumUser || some(tags, { slug: 'huumori' })) {
      return null
    }
    const text = ', me Tekniikan Maailmassa tiedämme, että luulon pahin vihollinen on tieto. Siksi me tuotamme tietoa enemmän kuin ' +
      'mikään muu media Suomessa. Me testaamme, mittaamme, vertailemme ja kokeilemme. Me myös välitämme muiden tuottamaa' +
      ' tutkittua tietoa. TM on ollut Suomen johtava tiedemedia jo 1960-luvulta lähtien. Siitä pidämme kiinni tulevaisuudessakin.<br/><br/>' +
      'Tähän kaikkeen tarvitsemme Sinun apuasi. Tule mukaan lyömään luulot tiedolla.'
    return <div styleName="main">
      <div styleName="image">
        <img src="https://cdn.tekniikanmaailma.fi/wp-content/uploads/2018/06/reijo_ruokanen_paakirjoitus-300x300.jpg" alt=""/>
      </div>
      <div styleName="title">Reijo Ruokanen</div>
      <div styleName="subtitle">Päätoimittaja</div>
      <div styleName="text">
        <p><span styleName="cap">Hyvä lukija</span><HTML>{text}</HTML></p>
        <p styleName="bold">
          Tue vastuullista journalismia. Tilaa Tekniikan Maailma.
        </p>
      </div>
      <div styleName="buttons">
        <NavLink onClick={() => window.dataLayer && window.dataLayer.push({ event: 'OrderCTA.click' })} to={{ link: 'https://tilaus.tekniikanmaailma.fi/uutinen?promoID=OM_pt-palkki&promoName=OM_TM&promoCreative=onlinetarjous&promoPosition=artikkelin-loppu-TM' }}>Tilaa Tekniikan
          Maailma</NavLink>
      </div>
    </div>
  }
}

export default OrderCTA
