import React, { Component } from 'react'
import tuuma from '../../../assets/logos/tuuma3.svg'
import arrow from '../../../assets/ui/ai-arrow.svg'
import arrowHover from '../../../assets/ui/ai-arrow-hover.svg'
import lock from '../../../assets/ui/lock.svg'
import PropTypes from 'prop-types'
import track from 'react-tracking'
import { connect } from 'kea'
import auth from '../../../kea/auth'
import { Link } from '../util/Links'
import WP from '../../../lib/WP'
import { withRouter } from 'react-router-dom'

import './AiWidget.scss'

/**
 *
 */
@connect({
  props: [
    auth, [
      'premiumUser',
    ],
  ]
})
class AiWidget extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
    window.addEventListener('resize', this.setHeight)
    setTimeout(() => {
      this.setHeight()
    }, 400)
  }

  async postQuestion () {
    this.props.history.push('/tuuma?question=' + encodeURIComponent(this.state.searchString))
  }

  handleEnter = event => {
    if (event.keyCode === 13 || event.key === 'Enter') {
      this.postQuestion()
    }
  }

  setHeight () {
    const textarea = document.getElementById('tuuma-textarea')
    if (textarea) {
      textarea.style.height = 'auto' // Reset the height to auto
      textarea.style.height = `${textarea.scrollHeight}px` // Set the height based on content
    }
    const ask = document.getElementById('ask')
    if (textarea && ask) {
      ask.style.height = 'auto' // Reset the height to auto
      ask.style.height = `${textarea.scrollHeight}px` // Set the height based on content
    }
  }

  textChange (e) {
    if (!e.currentTarget.value.substr(0, 200).endsWith('\n')) {
      this.setState({ searchString: e.currentTarget.value.substr(0, 200) })
      this.setHeight()
    }
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.setHeight)
  }

  render () {
    const { searchString, lockInput } = this.state
    const { premiumUser } = this.props

    return <div styleName="ai-block">
      <div styleName="ai-view">
        <div styleName="header">
          <div>
            <Link to={{ link: '/tuuma' }}>
              <img src={tuuma} alt="Tuuma"/>
            </Link>
          </div>
          <div>
            <p>Voit kysyä mitä vain Tekniikan Maailman Tuuma-tekoälyltä!</p>
          </div>
        </div>
      </div>
      <div styleName="footer">
        <div styleName="input-box">
          {premiumUser
            ? <textarea id="tuuma-textarea" rows="1"
              placeholder="Kirjoita kysymyksesi tähän."
              readOnly={lockInput} value={searchString} onKeyUp={this.handleEnter}
              onChange={e => this.textChange(e)}/>
            : <textarea id="tuuma-textarea" rows="1"
                        placeholder="Tilaajana voit käyttää Tuumaa"
                        readOnly={true} />
          }
          {premiumUser
            ? <button styleName="ask" id="ask" onClick={() => searchString && this.postQuestion()} disabled={lockInput}><img
              src={arrow} alt="Lähetä" styleName="no-hover"/><img
              src={arrowHover} alt="Lähetä" styleName="hover"/></button>
            : <a styleName="ask" id="ask" href={WP.getLoginUrl()}><img src={lock} alt="Kirjaudu"/></a>
          }
        </div>
      </div>
    </div>
  }
}

AiWidget.propTypes = {
  premiumUser: PropTypes.bool,
  history: PropTypes.object
}

export default track({ gtmContext: ['AiWidget'] })(withRouter(AiWidget))
