import PropTypes from 'prop-types'
import { kea } from 'kea'
import { channel, buffers } from 'redux-saga'
import { put, take } from 'redux-saga/effects'
import { LOCATION_CHANGE } from 'react-router-redux'
import { copyError, errors, is as errorIs, logException } from '../lib/errors'
import { isDevelopment, isHeadless } from '@otavamedia/om-component-library/lib/util/env'
import { STATUS } from '../lib/request-state'
import isMatch from 'lodash/isMatch'
import WP from '../lib/WP'
import { getLeikiContextual, actionResultIs, stripHTML } from '../lib/utils'
import DiskStorage, { settingsData, requestCache } from '../lib/DiskStorage'
import CacheDriver from '../lib/CacheDriver'
import headerLogic from './header'
import adsLogic from './ads'
import articleTypes from '../components/general/article/ArticleTypes'
import auth from './auth'

// you might ask: wtf is this?
// In short, styled-components provides API to export styles to HTML string,
// but the official API (ServerStyleSheet) works only on server side.
// Because our SSR is being executed in browser env (eg. client)
// we have to abuse the secret API to be able to access the styles and then
// append them to DOM to be served/cached.
//
// source: https://github.com/styled-components/styled-components/issues/1487
import { __DO_NOT_USE_OR_YOU_WILL_BE_HAUNTED_BY_SPOOKY_GHOSTS as scSecrets } from 'styled-components'
const StyleSheet = scSecrets.StyleSheet

const defaultSettings = {
  caching: true,
  headless: null,
}

const store = new DiskStorage(settingsData)
const responseChannel = channel(buffers.expanding())

export const errorPropTypes = PropTypes.oneOfType([
  PropTypes.bool,
  PropTypes.object,
])
let rendered = false // Used to trigger saga only once
let pageviewSent = false

window.isMatch = isMatch

export default kea({
  paths: ['application'], // default
  actions: () => ({
    start: () => true,
    pushPageview: (bool) => bool,
    setRendered: (bool) => bool,
    updateStatus: status => status,
    updateResolverStatus: status => status,

    updateSettings: (settings, save = true) => ({ ...settings, save }),

    setTaxonomies: (taxonomies) => ({ ...taxonomies }),
    setPostTypes: (postTypes) => ({ ...postTypes }),

    setViewData: (view) => ({ ...view }),
    addArticleToBlacklist: (articleModel) => ({ id: articleModel.id }),
    resetBlacklist: () => true,
    setError: (error) => error,
    setReferrer: (str) => str,
    loadViewDataFromPathname: (location) => ({ ...location }),
  }),

  reducers: ({ actions }) => ({
    settings: [defaultSettings, PropTypes.object, {
      [actions.updateSettings]: (state, { save, ...payload }) => ({ ...state, ...payload })
    }],

    status: [STATUS.NOT_REQUESTED, PropTypes.number, {
      [actions.updateStatus]: (state, payload) => payload
    }],

    resolverStatus: [STATUS.NOT_REQUESTED, PropTypes.number, {
      [actions.updateResolverStatus]: (state, payload) => payload
    }],

    rendered: [false, PropTypes.bool, {
      [actions.setRendered]: (state, bool) => bool,
    }],

    pageviewSent: [false, PropTypes.bool, {
      [actions.pushPageview]: (state, bool) => bool,
    }],

    contentTypes: [{}, PropTypes.object, {
      [actions.setTaxonomies]: (state, taxonomies) => ({ taxonomies }),
      [actions.setPostTypes]: (state, postTypes) => ({ postTypes }),
    }],

    location: [{}, PropTypes.object, {
      [LOCATION_CHANGE]: (state, payload) => ({ ...payload }),
    }],

    view: [{}, PropTypes.object, {
      [actions.setViewData]: (state, payload) => ({ ...payload }),
      // refactor a bit so that article is under it's own key and so on
      [actions.addArticleToBlacklist]: (state, { id }) => {
        return {
          ...state,
          blacklisted: [
            ...(state.blacklisted || []),
            id,
          ],
        }
      },
      [actions.resetBlacklist]: (state, payload) => ({ ...state, blacklisted: [] }),
    }],

    error: [false, errorPropTypes, {}, {
      [actions.setError]: (state, payload) => (payload ? copyError(payload) : payload),
    }],
    referrer: [[], PropTypes.string, {
      [actions.setReferrer]: (state, str) => [...state, str],
    }],
  }),

  start: function * () {
    yield put(this.actions.updateStatus(STATUS.NOT_REQUESTED))
    // console.log('application start running')
    while (true) {
      const action = yield take(responseChannel)
      yield put(action)
    }
  },

  takeEvery: ({ actions, workers }) => ({
    [actions.setViewData]: function * ({ type, payload }) {
      if (!payload._meta && isDevelopment()) {
        console.warn(
          `New view data doesn't contain metadata.
  Avoid setting view without meta as it's inefficient.`,
          payload
        )
      }
    },
    [LOCATION_CHANGE]: function * ({ type, payload }) {
      yield put(this.actions.setError(false)) // recover from errors on navigation
      yield put(this.actions.setReferrer(location.href))
      yield put(this.actions.loadViewDataFromPathname(payload))
    },
    [actions.updateSettings]: function * (action) {
      const { payload } = action
      const { save, ...data } = payload
      const { caching } = data // values may not exist, do not consider null as falsy value!

      if (caching === true) {
        WP.loadCacheDriver(new CacheDriver(requestCache, { orderMax: 500 }))
      } else if (caching === false) {
        WP.cache = false
      }

      if (save === true) {
        const settings = yield this.get('settings')
        yield store.put('settings', { saveTime: Date.now() }, {
          ...settings,
          ...data,
        })
      }
    },
  }),

  takeLatest: ({ actions, workers }) => ({
    [actions.start]: function * ({ payload }) {
      // console.log('application takeLatest start running')

      const status = yield this.get('status')

      if (status !== STATUS.REQUESTED) {
        yield put(actions.updateStatus(STATUS.NOT_REQUESTED))

        try {
          yield put(actions.updateStatus(STATUS.REQUESTED))

          const headless = isHeadless()

          // Setup ads early
          if (!headless) {
            yield put(adsLogic.actions.setupAds())
          } else {
            // console.log('Running headless, ads disabled')
          }

          // 1. Initialize settings
          const settings = {
            ...defaultSettings,
            ...yield store.get('settings'),
          }
          yield put(actions.updateSettings({
            ...settings,
            headless,
          }, false))

          // 1. Done initializing settings
          // 2. Getting data required to init the app

          const initData = yield WP.getInitialData()
          const { postTypes, taxonomies } = initData
          WP.setTaxonomies(taxonomies)
          WP.setPostTypes(postTypes)

          if (errorIs.error(initData)) {
            yield put(this.actions.setError(initData))
          } else {
            yield put(this.actions.setPostTypes(postTypes))
            yield put(this.actions.setTaxonomies(taxonomies))
          }
          // 2. Done getting init data

          // 3. Get menu
          // If the cache is on and user has visited before, this is instant
          // The menu might be stale though, if it is, ignore and load a fresh copy
          // after the user authentication is done.

          yield put(headerLogic.actions.loadMenu('2-0-ylanavigaatio'))
          const { payload: status } = yield take(actionResultIs(
            headerLogic.actions.updateStatus,
            [STATUS.DONE, STATUS.ERROR, STATUS.EXPIRED]
          ))
          const menuExpired = status === STATUS.EXPIRED

          // 3. End getting menu

          yield put(actions.updateStatus(STATUS.DONE))
          // 5. Get menu again, if expired
          if (menuExpired) {
            // console.log('menu expired')
            yield put(headerLogic.actions.loadMenu('2-0-ylanavigaatio', {
              cache: {
                acceptExpired: false,
              }
            }))
            yield take(actionResultIs(
              headerLogic.actions.updateStatus,
              [STATUS.DONE, STATUS.ERROR]
            ))

            // console.log('Reloaded menu with status', reloadStatus)
          }
          // 6. Setup ad context based on location
          yield put(adsLogic.actions.setAdContextByLocation(window.location))
        } catch (e) {
          yield put(actions.updateStatus(STATUS.ERROR))
          console.log(e)
        }
      }
    },
    [actions.pushPageview]: function * ({ payload }) {
      if (pageviewSent === payload) {
        return
      }
      pageviewSent = payload
      if (!pageviewSent) {
        return
      }
      if (!isHeadless()) {
        try {
          console.log('PAGEVIEW_SENT')
          const view = yield this.get('view')
          const canAccessArticle = yield this.get('canAccessArticle')
          const userData = yield this.get('userData')
          const { OM_GTM_ID, google_tag_manager: GTM } = window
          const {
            categories, tags, author, modifiedDate, createdDate, forSubscribers, commentCount, isAd, articleType, _meta,
            leikiData, neuwoData, adCampaign, id, title
          } = view
          let gtmData

          GTM && GTM[OM_GTM_ID] && GTM[OM_GTM_ID].dataLayer.reset()

          if (articleType) {
            gtmData = {
              event: 'virtualPageview',
              PaywallState: canAccessArticle(view) ? 'Open' : 'Locked',
              masterId: userData && userData.masterId,
              articleCategories: categories && categories[0] ? categories.map(x => x.name).join(', ') : null,
              articleTags: tags && tags[0] ? tags.map(x => x.name).join(', ') : null,
              cbAuthor: author ? author.name : null,
              cbSections: categories && categories[0] ? categories[0].name : null,
              modifiedTime: modifiedDate,
              publishDate: createdDate,
              paidUser: ((userData && userData.asauth && userData.asauth.access_level) || 1) > 2 ? '1' : '0',
              paidArticle: !!forSubscribers,

              PageType: articleType === articleTypes.SPECIAL ? 'erikoistaitto' : 'artikkeli',
              Section: (categories && categories[0] && categories[0].name) || '',
              PrimaryCategory: (categories && categories[0] && categories[0].name) || '',
              Categories: categories && categories[0] ? categories.map(x => x.name).join(', ') : '',
              Tags: tags && tags[0] ? tags.map(x => x.name).join(', ') : '',
              Author: author ? author.name : '',
              DatePublish: createdDate.substr(0, 10),
              DatePublishTime: createdDate.substr(createdDate.length - 8, 5),
              DatePublishTimestamp: Math.round(new Date(createdDate).getTime() / 1000),
              DateModified: modifiedDate.replace('T', ' '),
              PaidContent: !!forSubscribers,
              PaidUser: ((userData && userData.asauth && userData.asauth.access_level) || 1) > 2,
              CommentCount: commentCount,
              LeikiSmartProfiles: leikiData,
              NeuwoTags: neuwoData,
              PostId: id,
              PageTitle: stripHTML(title),
            url: window.location.href
            }

            if (isAd) {
              const authorName = (author ? author.name : '').replace(/mainos: ?/ig, '')
              gtmData.AdType = 'native'
              gtmData.AdAdvertiser = authorName
              gtmData.AdCampaignName = adCampaign
            }
          } else if (_meta) {
            // (Etusivu, sivu
            if (view.taxonomy === 'category') {
              gtmData = {
                event: 'virtualPageview',
                masterId: userData && userData.masterId,
                PageType: 'kategoria',
              PageTitle: document.title,
              url: window.location.href
              }
            } else if (view.taxonomy === 'post_tag') {
              gtmData = {
                event: 'virtualPageview',
                masterId: userData && userData.masterId,
                PageType: 'avainsana',
              PageTitle: document.title,
              url: window.location.href
              }
            }
          } else if (view.type === 'index') {
            gtmData = {
              event: 'virtualPageview',
              masterId: userData && userData.masterId,
              PageType: 'etusivu',
            PageTitle: 'Etusivu',
            url: window.location.href
            }
          } else {
            gtmData = {
              event: 'virtualPageview',
              masterId: userData && userData.masterId,
              PageType: 'sivu',
            PageTitle: document.title,
            url: window.location.href
            }
          }
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push(gtmData)

          if (window.googletag && window.googletag.cmd) {
            window.googletag.cmd.push(() => {
              cX.getSegments('f1ab28b7c9202ce3defa8bb2979a88f90c7a5ab2', function(segments) {
                var cxContextualIds = [], cxSegmentIds = [];
                cX.Array.forEach(segments, function (cxTypedSegment, index) {
                  if (cxTypedSegment.type == 'contextual') {
                    cxContextualIds.push(cxTypedSegment.id);
                  } else if (cxTypedSegment.type == 'traffic') {
                    cxSegmentIds.push(cxTypedSegment.id);
                  }
                });
                window.googletag.pubads().setTargeting("CxContext", cxContextualIds.join());
                window.googletag.pubads().setTargeting("CxSegments", cxSegmentIds.join());
              });


              const leikiContextual = getLeikiContextual(leikiData || {})
              if (leikiContextual) {
                window.googletag.pubads().setTargeting('leiki_contextual', leikiContextual)
              }
            })
          }
        } catch (e) {
          console.log('Exception in pushPageview')
          console.log(e)
        }
      }
    },
    [actions.setRendered]: function * ({ payload }) {
      if (!payload) {
        yield put(this.actions.pushPageview(false))
      }
      if (rendered === payload) {
        return
      }
      rendered = payload
      window.READY_TO_RENDER = payload
      if (window.READY_TO_RENDER) {
        console.log('READY_TO_RENDER:', window.READY_TO_RENDER)
      }
      if (!rendered) {
        return
      }
      if (isHeadless()) {
        // append/expose styled-component styles to DOM so they can be served and cached
        const obj = document.querySelectorAll('style[data-styled-components]')[0]
        const template = document.createElement('template')
        template.innerHTML = StyleSheet.instance.toHTML()
        obj.parentNode.insertBefore(template.content.firstChild, obj)

        // console.log('removing scripts')

        Array.from(document.querySelectorAll('script:not(#article-ld-json)')).forEach(script => {
          script.remove()
        })
        document.querySelector('#cmp-faktor-io-parent') && document.querySelector('#cmp-faktor-io-parent').remove()
        const puppe = document.createElement('span')
        puppe.id = 'readyToRender'

        document.body.appendChild(puppe)
      } else {
        // make sure a pageview is triggered, if it hasn't already
        yield put(this.actions.pushPageview(true))
      }

      if (window.startTime) {
        console.log(`Took ${Date.now() - window.startTime}ms to be ready`)
        window.startTime = false
      }
    },
    [actions.loadViewDataFromPathname]: function * ({ type, payload }) {
      try {
        const status = yield this.get('status')
        if (status !== STATUS.DONE) {
          // For some reason Safari triggers redundant LOCATION_CHANGE before application start when user
          // navigates back from other site by using "back button". If that is the case, just ignore this warning.
          console.warn('Application not ready, could not resolve view data from path name')
          return
        }

        const { pathname, search } = payload
        const link = pathname + search

        const view = yield this.get('view')

        // In case the view data is not set, or it's different than new pathname,
        // clear the view data by setting it to the current pathname.
        // In case user is navigating with back/forward button, the view data is not being updated
        // via. Link component. This will make sure the view is being matched with the path.
        if (!view || !view.link || link.split('?')[0] !== view.link.split('?')[0]) {
          yield put(this.actions.setViewData({ link }))
        }

        let pageNumber = false
        const parts = pathname.split('/').filter(x => {
          const num = parseInt(x, 10)

          // prevent from matching "50-vuotta-sitten"
          // eslint-disable-next-line eqeqeq
          if (!isNaN(Number(num)) && num == x) {
            pageNumber = num
            return false
          }

          if (x.length) {
            return true
          }
          return false
        })

        const maybeTaxonomyName = parts[0]
        const maybeTerm = parts[parts.length - 1]

        const taxonomy = WP.getTaxFromURLBase(maybeTaxonomyName)
        if (taxonomy) {
          const r = yield WP.getTerms(taxonomy, {
            slug: maybeTerm,
          })
          const terms = r.data

          if (terms) {
            if (terms[0]) {
              terms[0]._meta.paged = pageNumber || 1

              yield put(this.actions.setViewData(terms[0]))
              return
            }
          } else {
            yield put(this.actions.setError(errors.notFoundResponseError()))
          }
        }

        // If nothing matches, view is not overwritten.
        // That's why going back to Taxonomy from a single post works.
      } catch (e) {
        if (e.message === 'Ei tuloksia') {
          yield put(this.actions.setError(errors.notFoundResponseError()))
        } else {
          logException(e)
        }
      }
    },
  }),

  selectors: ({ selectors }) => ({
    ready: [
      () => [selectors.status, selectors.error],
      (status, error) => Boolean(status === STATUS.DONE) || Boolean(error),
      PropTypes.bool,
    ],
    pathname: [
      () => [selectors.view],
      view => view.link || window.location.pathname,
      PropTypes.string,
    ],
    isFeatureArticle: [
      () => [selectors.view],
      view => view.articleType === articleTypes.FEATURE || view.articleType === articleTypes.FEATUREV2,
      PropTypes.bool,
    ],
    isArticleView: [
      () => [selectors.view],
      view => view.type === 'post',
      PropTypes.bool,
    ],
    canAccessArticle: [
      () => [auth.selectors.canAccessArticle],
      canAccessArticle => canAccessArticle,
      PropTypes.bool
    ],
    userData: [
      () => [auth.selectors.userData],
      data => data,
      PropTypes.object
    ],
    isMagazineView: [
      () => [selectors.location],
      (location) => {
        return !!location.pathname.match(/\/(mainos|teema)?lehti\/[^/]+\/?$/)
      },
      PropTypes.bool,
    ],
    isMagazineArticleView: [
      () => [selectors.location],
      (location) => {
        return !!location.pathname.match(/\/(mainos|teema)?lehti\/[^/]+\/[^/]+/)
      },
      PropTypes.bool,
    ],
    isAdMagazine: [
      () => [selectors.location],
      (location) => {
        return !!location.pathname.match(/\/mainoslehti\//)
      },
      PropTypes.bool,
    ],
    isThemeMagazine: [
      () => [selectors.location],
      (location) => {
        return !!location.pathname.match(/\/teemalehti\//)
      },
      PropTypes.bool,
    ],
  })
})
