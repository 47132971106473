import React, { Component, Fragment } from 'react'
import tuuma from '../../assets/logos/tuuma.svg'
import closed from '../../assets/ui/closed.svg'
import tuumausTauko from '../../assets/logos/tuumausTauko.svg'
import tuuma2 from '../../assets/logos/tuuma2.png'
import tuumaAnim from '../../assets/ui/tuuma-anim.gif'
import arrow from '../../assets/ui/ai-arrow.svg'
import arrowHover from '../../assets/ui/ai-arrow-hover.svg'
import lock from '../../assets/ui/lock.svg'
import circle from '../../assets/ui/questionmark.svg'
import circleGray from '../../assets/ui/questionmark-gray.svg'
import hideAll from '../../assets/ui/chevron-small-down-red.svg'
import hideAllHover from '../../assets/ui/chevron-small-down-red-hover.svg'
import PropTypes from 'prop-types'
import track from 'react-tracking'
import { Link } from '../general/util/Links'
import HTML from '../general/util/HTML'
import Helmet from 'react-helmet'
import { getURL } from '../../lib/WPClient'
import {
  FacebookNoBgLogo,
  MailNoBgLogo,
  TwitterNoBgLogo,
} from '../widgets/Icons'
import { connect } from 'kea'
import auth from '../../kea/auth'
import application from '../../kea/application'
import WP from '../../lib/WP'
import querystring from 'querystring'

import './AiView.scss'

/**
 *
 */
@connect({
  actions: [
    application, [
      'setRendered'
    ],
  ],
  props: [
    auth, [
      'premiumUser',
    ],
  ]
})
class AiView extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showExamples: true,
      viewingExample: false,
      examples: [],
      items: [],
      enabled: undefined
    }
    const params = querystring.parse(window.location.search.replace('?', ''))
    if (params.question) {
      this.state.searchString = params.question
      this.state.showExamples = false
      setTimeout(() => this.postQuestion(), 100)
    } else {
      this.getExamples()
    }
  }

  async getExamples () {
    const { enabled, examples } = await WP.aiExamples()
    this.setState({ examples: examples || [], enabled: !!enabled }, () => this.actions.setRendered(true))
  }

  processResponse (answer) {
    return answer
      // create tables:
      .replace(/\n\n\|/g, '\n<table><tr><td>').replace(/\|\n\n/g, '</td></tr></table>')
      .replace(/\n(\|-+)+\|\n/g, '\n')
      .replace(/\|\n\|/g, '</td></tr><tr><td>').replace(/\|/g, '</td><td>')
      // fix a numbered list with a "header:"
      .replace(/\n(\d+\.[^:]+:)/g, '\n<b>$1</b>')
      // create numbered lists:
      .replace(/\n((\d+\..+(\n|$))+)/g, '<ol>\n$1</ol>').replace(/\n\d+\. ([^\n]+)/g, '<li>$1</li>').replace(/\n<\/ol>/, '</ol>')

      // replace line breaks:
      .replace(/\n/g, '<br/>')
  }

  setQuestion (searchString) {
    this.setState({ searchString }, () => this.postQuestion())
  }

  async postQuestion () {
    const searchString = this.state.searchString
    if (this.state.viewingExample) {
      this.setState({ showExamples: false, viewingExample: false, items: [], loading: true, lockInput: true, searchString: '', activeQuestion: searchString })
    } else {
      this.setState({ showExamples: false, loading: true, lockInput: true, searchString: '', activeQuestion: searchString })
    }
    setTimeout(() => window.scrollTo(0, document.body.scrollHeight), 200)
    window.dataLayer && window.dataLayer.push({ event: 'aiQuestion', question: searchString })
    const result = await WP.aiSearch(searchString)
    result.hash = null // prevent share button from showing

    // filter unique source links
    const uniqueLinks = new Set()
    const newLinks = []
    for (const obj of result.source_documents) {
      if (!uniqueLinks.has(obj.metadata.url)) {
        uniqueLinks.add(obj.metadata.url)
        newLinks.push(obj)
      }
    }
    result.source_documents = newLinks
    result.answer = this.processResponse(result.answer)
    const items = this.state.items ? [...this.state.items] : []
    items.push(result)
    this.setState({
      items,
      loading: false,
      lockInput: false
    }, () => setTimeout(() => { this.setHeight(); window.scrollTo(0, document.body.scrollHeight) }, 200))
  }

  renderExamples () {
    return <div styleName="examples">
      Voit kysyä esimerkiksi:
      <div>
        {this.state.examples.map((ex, idx) => <button key={idx} styleName={ex.open ? 'open' : ''}
          onClick={() => {
            this.setState({ viewingExample: true, showExamples: false, items: [ex] })
          }}>
          <div styleName={idx ? 'dt no-mobile' : 'dt'}>{ex.question}</div>
        </button>)
        }
      </div>
    </div>
  }

  handleEnter = event => {
    if (event.keyCode === 13 || event.key === 'Enter') {
      this.postQuestion()
    }
  }

  toggleSources = (item) => {
    item.showLinks = !item.showLinks
    this.setState({ items: this.state.items })
  }

  renderAnswer (item, idx, loading) {
    return <div styleName="question-answer" key={idx}>
      <div styleName="question">
        <div>
          <img src={circle} alt="?"/>
        </div>
        <div>{item.question}
        </div>
      </div>
      <div styleName="answer">
        <div>
          <img src={loading ? tuumaAnim : tuuma2} alt="Tuuma"/>
        </div>
        <div styleName={loading ? 'loading' : null}>
          <HTML>{item.answer}</HTML>
          {!loading && item.hash
            ? <div styleName="share">
              <Link to={{ link: getURL('/wp-json/om/v1/tuuma/image?id=' + item.hash) }} target="_blank">Jaa
                vastaus <TwitterNoBgLogo/> <FacebookNoBgLogo/> <MailNoBgLogo/></Link>
              <div style={{ clear: 'both', marginBottom: '1rem' }}></div>
            </div>
            : null
          }
        </div>
      </div>
      {(item.source_documents && item.source_documents.length)
        ? <div styleName="sources">
          <button styleName="title" onClick={() => this.toggleSources(item)}>
            <span>Lähteet</span>
            <img styleName={item.showLinks ? 'open no-hover' : 'no-hover'} src={hideAll} alt="Näytä/piilota"/>
            <img styleName={item.showLinks ? 'open hover block' : 'hover block'} src={hideAllHover}
              alt="Näytä/piilota"/>
          </button>
          <ul styleName={item.showLinks ? 'show-all' : ''}>
            {item.source_documents.map((link, idx2) => <li key={idx2}><Link
              to={{ link: link.metadata.url }}><HTML>{link.metadata.title}</HTML></Link></li>)}
          </ul>
        </div>
        : null}
      {item.relevant_questions && item.relevant_questions.length
        ? <div styleName="relevant">
          <div styleName="rel-icon">
            <img src={circleGray} alt="?"/>
          </div>
          <div styleName="qs">
            {item.relevant_questions.map(q => <button onClick={() => this.setQuestion(q)}>{q}</button>)}
          </div>
        </div>
        : null
      }
    </div>
  }

  setHeight () {
    const textarea = document.getElementById('tuuma-textarea')
    if (textarea) {
      textarea.style.height = 'auto' // Reset the height to auto
      textarea.style.height = `${textarea.scrollHeight}px` // Set the height based on content
    }
    const ask = document.getElementById('ask')
    if (ask) {
      ask.style.height = 'auto' // Reset the height to auto
      ask.style.height = `${textarea.scrollHeight}px` // Set the height based on content
    }
  }

  textChange (e) {
    if (!e.currentTarget.value.substr(0, 200).endsWith('\n')) {
      this.setState({ searchString: e.currentTarget.value.substr(0, 200) })
      this.setHeight()
    }
  }

  render () {
    const { showExamples, items, searchString, lockInput, loading, enabled, activeQuestion } = this.state
    const { premiumUser } = this.props

    return <Fragment>
      <Helmet>
        <title>
          {'Tekniikan Maailma - TM Tuuma'}
        </title>
        <meta property="og:title" content="Tekniikan Maailman tekoäly"/>
        <meta property="og:image" content={window.location.origin + '/assets/logos/tuuma-share.jpg?20231120'}/>
        <meta property="og:description" content="Voit puhua Tuumalle kuin ystävälle. Kysy siltä mitä vain, ja Tuuma hakee
          vastauksia kysymyksiisi Tekniikan Maailman artikkeleista. Olemme vasta rakentamassa Tuumaa, joten
          kummallisiakin asioita voi tapahtua!"/>
      </Helmet>
      {enabled !== false
        ? <div styleName="ai-view">
          <div styleName="header">
            <img src={tuuma} alt="Tuuma"/>
            <h2>Tekniikan Maailman tekoäly</h2>
            <p>Voit puhua Tuumalle kuin ystävälle. Kysy siltä mitä vain, ja Tuuma hakee vastauksia kysymyksiisi Tekniikan
              Maailman artikkeleista. Olemme vasta rakentamassa Tuumaa, joten kummallisiakin asioita voi tapahtua!</p>
          </div>
          {!premiumUser && <div styleName="login-link"><a href={WP.getLoginUrl()} styleName="with-icon"><span>Kirjaudu tilille</span></a></div>}
          {showExamples && this.renderExamples()}
          {!showExamples && items && items.map((item, idx) => this.renderAnswer(item, idx))}
          {loading && this.renderAnswer({
            question: activeQuestion,
            answer: 'Etsin tietoa TM:n artikkeleista.',
            source_documents: []
          }, 999, true)}
        </div>
        : <div styleName="ai-view">
          <div styleName="header locked">
            <img src={tuumausTauko} alt="Tuumaustauko"/>
            <h2 styleName="black">Voi ei, tekniikka tökkii!</h2>
            <p>Tuuma on ottanut nyt pienen tuumaustauon. Korjaamme ongelmia ja yritämme saada palvelun taas toimintaan mahdollisimman pian.</p>
          </div>
        </div>
      }
      <div styleName="footer">
        {enabled !== false
          ? <Fragment>
            <div styleName="input-box">
              {premiumUser
                ? <textarea id="tuuma-textarea" rows="1"
                  placeholder="Kirjoita kysymyksesi tähän."
                  readOnly={lockInput} value={searchString} onKeyUp={this.handleEnter}
                  onChange={e => this.textChange(e)}/>
                : <input type="text" placeholder="Tilaajana voit käyttää Tuumaa" readOnly={true}/>
              }
              {premiumUser
                ? <button styleName="ask" id="ask" onClick={() => searchString && this.postQuestion()} disabled={lockInput}><img
                  src={arrow} alt="Lähetä" styleName="no-hover"/><img
                  src={arrowHover} alt="Lähetä" styleName="hover"/></button>
                : <a styleName="ask" id="ask" href={WP.getLoginUrl()}><img src={lock} alt="Kirjaudu"/></a>
              }
            </div>
            <p>
              Tuuma voi tehdä virheitä. Muista tarkistaa faktat. <Link to={{ link: '/lehti/20b-2023/tekniikan-maailma-avasi-oman-tekoalyhaun-nain-syntyi-ja-nain-toimii-tm-tuuma/' }}>Lue lisää Tuumasta.</Link>
            </p></Fragment>
          : <div styleName="input-box">
            <input type="text" placeholder="Tuuma ei vastaa tällä hetkellä" readOnly={true}/>
            <button styleName="ask locked"><img src={closed} alt="Suljettu"/></button>
          </div>
        }
      </div>
    </Fragment>
  }
}

AiView.propTypes = {
  premiumUser: PropTypes.bool
}

export default track({ gtmContext: ['AiView'] })(AiView)
